@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

// Variables
$primary-color: #723efe;
$secondary-color: #349bff;
$background-color: rgba(249, 250, 251, 0.3);

// Mixins
@mixin gradient-background {
  background-image: linear-gradient(
    90deg,
    $primary-color,
    $secondary-color
  ) !important;
}

// Styles
.App {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

/* Table */
.table {
  background-color: #fff;
  border: none;
  text-align: center;

  td {
    vertical-align: middle;
  }
}

.download-btn {
  background-color: #273041;
  color: #fff;
  border: none;
  outline: none;
  padding: 5px;
  margin-top: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.download-image-link {
  text-decoration: underline !important;
  cursor: pointer;
}
