/* Variables */
$primary-color: #7637fe;

/* Sidebar */
// $sidebar-width: 180px;

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: scroll;
  position: fixed;
}

.MuiList-root.MuiList-padding.css-h4y409-MuiList-root::-webkit-scrollbar {
  display: none !important;
}

.avatar {
  display: flex;
  align-items: center;
  padding: 50px 10px;
}

.sidebar-list {
  display: flex;
  align-items: center;
}

.sidebar-listItem {
  height: 40px;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 200px;
}

.sidebar-link {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 !important;
  margin: 5px 0;
  width: 100%;
  font-size: 14px;
  letter-spacing: 1px;
  transition: all 0.2s ease-in-out;
}

.sidebar-list-text {
  color: #fff;
}

.sidebar-link.active,
.sidebar-link:hover {
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.04) !important;
  border-radius: 8px;
  border-left: 5px solid $primary-color;
  font-weight: 700;
  padding: 0 !important;
}

.sidebar-link:hover {
  color: #fff !important;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.04) !important;
}

.sidebar-list-text {
  margin-bottom: 0 !important;
}
