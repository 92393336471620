.truck-details-container {
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;

  h3 {
    text-align: center;
  }

  button {
    background-color: #273041;
    color: #fff;
    border: none;
    outline: none;
    padding: 5px;
    margin-top: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  button:hover {
    background-color: #273041 !important;
  }
}

@media screen and (max-width: 1200px) {
  .truck-details-container {
    width: 100%;
  }
}
